import './style.scss'

import React, { Component, ReactElement } from 'react'

import OutlinedThemeButton from '../../../../localCommon/Button/ThemeOutlinedButton/ThemeOutlinedButtons'
import { growCourseCatalog } from '../../../../localCommon/constant'
import ISubscribingView from '../../../../localCommon/Interfaces/ISubscribingView'
import StringHelper from '../../../../localCommon/StringHelper/StringHelper'
import JobOverviewCard from '../../../Jobs/CandidateView/JobOverviewCard'
import JobDto from '../../../Jobs/Dtos/JobDto'
import { candidateViewJobBoardRoute } from '../../../RouteConstants'
import CoreAssessments from '../../CoreAssessments/CoreAssessments'
import OTEStanding from '../../OTEStanding/OTEStanding'
import { SDRRole } from '../../Presenters/DropdownOptions'
import IHomePresenter from '../../Presenters/Interfaces/IHomePresenter'
import AssessmentMessageBox from './AssessmentMessageBox'
import IAssessmentResultPresenter from './Presenter/IAssessmentResultPresenter'

type Props = {
  homePresenter: IHomePresenter
  presenter: IAssessmentResultPresenter
}

class AssessmentResult extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.initialize()
  }

  render(): ReactElement {
    const { homePresenter, presenter } = this.props
    const jobsAppliedTo = presenter.getJobsAppliedTo()
    return (
      <div className='assessmentResult'>
        {jobsAppliedTo.length === 0 ? (
          <AssessmentMessageBox presenter={presenter} />
        ) : (
          <div>
            <div className='jobs-container-home-page'>
              <h2 className='jobs-title'>My Applications</h2>
              {jobsAppliedTo.map((job: JobDto) => {
                return (
                  <div key={job.id} onClick={() => this.handleJobClick(job.id)}>
                    <JobOverviewCard
                      presenter={presenter.getJobOverviewPresenter(job)}
                      key={job.id}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <div className='section-with-border'>
          <p className='subtitle2'>
            Click here if you'd like to receive an email whenever we have new roles!
          </p>
          <a href={'https://www.databased.com/subscribe'} target='_blank' rel='noreferrer'>
            <OutlinedThemeButton id='course-catalog' type='button'>
              SUBSCRIBE TO RECEIVE JOB UPDATES
            </OutlinedThemeButton>
          </a>
        </div>
        <CoreAssessments presenter={presenter} />

        {this.shouldShowOTEStanding() && (
          <OTEStanding
            editModeToggler={homePresenter}
            isLoading={homePresenter.isLoading()}
            presenter={homePresenter.getDataRowsPresenter()}
          />
        )}

        <div className='section-with-border'>
          <p className='subtitle2'>View our ever growing sales course catalog</p>
          <a href={growCourseCatalog} target='_blank' rel='noreferrer'>
            <OutlinedThemeButton id='course-catalog' type='button'>
              Visit course catalog
            </OutlinedThemeButton>
          </a>
        </div>
      </div>
    )
  }

  update = (): void => {
    this.setState({})
  }

  shouldShowOTEStanding = (): boolean => {
    const { homePresenter } = this.props

    if (StringHelper.getFirstChars(homePresenter.getRole()) === SDRRole) {
      return false
    }

    return true
  }

  handleJobClick = (jobId: number): void => {
    window.location.href = `${candidateViewJobBoardRoute}/${jobId}`
  }
}

export default AssessmentResult
