import './style.css'

import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, List, ListItem, Typography } from '@material-ui/core'
import React, { Component, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import images from '../../images'
import { jobIdKey } from '../../localCommon/CookieKeys'
import Header from '../../localCommon/HeaderWithProfile/Header'
import ISubscribingView from '../../localCommon/Interfaces/ISubscribingView'
import LoadingSpinner from '../../localCommon/LoadingSpinner/LoadingSpinner'
import IMenuVisibilityManager from '../../localCommon/SlidingMenu/MenuVisibilityManager/IMenuVisibilityManager'
import {
  candidateProfileRoute,
  candidateViewJobBoardRoute,
  jobManagementRoute
} from '../RouteConstants'
import JobDto from './Dtos/JobDto'
import IJobListingsPresenter from './Presenters/Interfaces/IJobListingsPresenter'

type Props = {
  menuVisibilityManager: IMenuVisibilityManager
  presenter: IJobListingsPresenter
}

class JobListings extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.initialize()
    this.props.presenter.setView(this)
  }

  public render(): ReactElement {
    const jobs = this.props.presenter.getJobListings()
    const shouldShowApplicantsForJobWindow = this.props.presenter.shouldShowApplicantsForJobWindow()

    if (this.props.presenter.isLoading()) {
      return <LoadingSpinner />
    }

    const { menuVisibilityManager, presenter } = this.props
    return (
      <div className='job-listing-page'>
        <Header menuVisibilityManager={menuVisibilityManager} />

        <Container maxWidth='lg'>
          <div className='jobManagementHeader'>
            <Typography variant='h4' component='h1' className='jobListingsTitle'>
              Job Management
            </Typography>
            <Link to={`${jobManagementRoute}/new`} className='createJobButtonLink'>
              <button type='button' className='createJobButton'>
                Create Job
              </button>
            </Link>
          </div>
          <List className='jobList'>
            {jobs.map((job: JobDto) => (
              <ListItem key={job.id} className='jobItem'>
                <div
                  className='jobCard'
                  onClick={() => {
                    window.location.pathname = `${jobManagementRoute}/${job.id}`
                  }}
                >
                  <div className='jobInfo'>
                    <h2>{job.jobTitle}</h2>
                    <p>{job.location}</p>
                    <p>OTE: {presenter.getJobCompensationRange(job)}</p>
                  </div>
                </div>
                <div className='action-buttons'>
                  <p
                    className='copy-url'
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://www.product.databased.com/${candidateViewJobBoardRoute.substring(1)}?${jobIdKey}=${job.id}`
                      )
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                    &nbsp;Copy URL
                  </p>
                  <p className='view-applicants' onClick={() => this.handleViewApplicants(job.id)}>
                    View Applicants
                  </p>
                </div>
              </ListItem>
            ))}
          </List>
        </Container>

        {shouldShowApplicantsForJobWindow && this.renderApplicantsWindow()}
      </div>
    )
  }

  private handleViewApplicants = (jobId: number): void => {
    this.props.presenter.clickedViewApplicantsForJob(jobId)
  }

  private renderApplicantsWindow(): ReactElement {
    const { presenter } = this.props
    const applicants = presenter.getApplicantsForSelectedJob()
    const jobTitle = presenter.getSelectedJobTitle()
    const incompleteAssessmentScores = new Set([-1, undefined, null])
    return (
      <div className='applicants-slideout'>
        <div className='applicants-header'>
          <h3>{jobTitle}</h3>
          <img
            alt='close-drawer-icon'
            className='close-button'
            onClick={this.handleCloseClick}
            src={images.closeIcon}
          />
        </div>
        <div className='applicants-list'>
          {applicants.length ? (
            <ul>
              {applicants.map((applicant, index) => (
                <li
                  key={index}
                  className='applicant-row'
                  onClick={() => {
                    window.location.pathname = `${candidateProfileRoute}/${applicant.userId}`
                  }}
                >
                  <p>
                    {applicant.name} | Score:{' '}
                    {incompleteAssessmentScores.has(applicant?.assessmentScore)
                      ? 'INCOMPLETE'
                      : applicant?.assessmentScore.toFixed(2)}
                  </p>
                  <p>Date applied: {applicant.applicationDate}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No applicants yet.</p>
          )}
        </div>
      </div>
    )
  }

  private handleCloseClick = (): void => {
    this.props.presenter.closeApplicantsForJobWindow()
  }

  update(): void {
    this.setState({})
  }
}

export default JobListings
