import IAssessmentScoreFetcher from '../../../../../APIs/AssessmentScoreFetcher/IAssessmentScoreFetcher'
import IFetcher from '../../../../../Drivers/Interfaces/IFetcher'
import IAssessmentResultHandler from '../../../../../Handlers/AssessmentResultHandler/IAssessmentResultHandler'
import { serverBaseUrl } from '../../../../../localCommon/constant'
import ISubscribingView from '../../../../../localCommon/Interfaces/ISubscribingView'
import IJobOverviewCardPresenter from '../../../../Jobs/CandidateView/IJobOverviewCardPresenter'
import IJobOverviewCardPresenterFactory from '../../../../Jobs/CandidateView/IJobOverviewCardPresenterFactory'
import JobDto from '../../../../Jobs/Dtos/JobDto'
import ApplicationDto from '../../../../Jobs/Presenters/Interfaces/ApplicationDto'
import CoreAssessmentDto from '../../../CoreAssessments/AssessmentBars/CoreAssessmentDto'
import AssessmentStageDto from './AssessmentStageDto'
import IAssessmentResultPresenter from './IAssessmentResultPresenter'

export const assessmentMetricBenchmarkScore = 3.6
export const getPercentileNumberGivenPercentile = (givenPercentile: number): number => {
  const invertedPercentile = 100 - givenPercentile
  if (invertedPercentile <= 5) {
    return 5
  }
  if (invertedPercentile <= 10) {
    return 10
  }
  if (invertedPercentile <= 15) {
    return 15
  }
  if (invertedPercentile <= 20) {
    return 20
  }
  if (invertedPercentile <= 25) {
    return 25
  }

  if (invertedPercentile >= 75) {
    // For percentiles greater than or equal to 75, return the nearest multiple of 5
    return Math.round(invertedPercentile / 5) * 5
  }

  // For percentiles between 25 and 75, return the nearest multiple of 10
  return Math.round(invertedPercentile / 10) * 10
}

class AssessmentResultPresenter implements IAssessmentResultPresenter {
  private loading: boolean
  private score: number
  private view: ISubscribingView | null
  private selectedMetric: number | null
  private applications: ApplicationDto[]
  private jobIdsAppliedTo: Set<number>
  private jobs: JobDto[]
  private coreAssessmentDtos: CoreAssessmentDto[]

  constructor(
    private readonly assessmentResultHandler: IAssessmentResultHandler,
    private readonly scoreFetcher: IAssessmentScoreFetcher,
    private readonly assessmentId: number,
    private readonly userId: number,
    private readonly fetcher: IFetcher,
    private readonly jobOverviewCardPresenterFactory: IJobOverviewCardPresenterFactory
  ) {
    this.score = 0
    this.loading = true
    this.selectedMetric = null
    this.view = null
    this.coreAssessmentDtos = []
    this.applications = []
    this.jobs = []
    this.jobIdsAppliedTo = new Set()
  }

  public hasFailedAssessment(): boolean {
    return this.score < assessmentMetricBenchmarkScore
  }

  public isLoadingMetrics(): boolean {
    return this.loading
  }

  public async initialize(): Promise<void> {
    this.coreAssessmentDtos = await this.assessmentResultHandler.getAssessmentMetrics(this.userId)
    this.score = await this.scoreFetcher.getAssessmentScore(this.assessmentId)
    this.loading = false

    const userApplicationsResult = await this.fetcher.fetch({
      body: null,
      method: 'GET',
      url: `${serverBaseUrl}user/${this.userId}/applications`
    })

    if (Array.isArray(userApplicationsResult)) {
      this.applications = userApplicationsResult
      this.applications.forEach((application) => this.jobIdsAppliedTo.add(application.jobId))
    }

    const jobsResult = await this.fetcher.fetch({
      url: `${serverBaseUrl}jobs`,
      method: 'GET',
      body: null
    })
    if (Array.isArray(jobsResult)) {
      this.jobs = jobsResult
    }

    this.updateView()
  }

  public getJobsAppliedTo(): JobDto[] {
    return this.jobs.filter((job) => this.jobIdsAppliedTo.has(job.id))
  }

  public getJobOverviewPresenter(dto: JobDto): IJobOverviewCardPresenter {
    return this.jobOverviewCardPresenterFactory.buildPresenter(
      dto,
      this.applications.find((app) => app.jobId === dto.id) ?? null
    )
  }

  public getSelectedMetric(): CoreAssessmentDto | null {
    return this.coreAssessmentDtos?.find((dto) => dto.id === this.selectedMetric) ?? null
  }

  public shouldShowMetricDetail(): boolean {
    return this.selectedMetric !== null
  }

  public selectAssessmentMetricToShow(id: number | null): void {
    this.selectedMetric = id
    this.updateView()
  }

  public getAssessmentMetrics(): CoreAssessmentDto[] {
    return this.coreAssessmentDtos
  }

  public getMessageToDisplay(): AssessmentStageDto | null {
    return {
      header: 'Finished! 🚀',
      title: 'Congratulations on completing the first interview!',
      content: `We are currently reviewing your responses to identify any roles that might be a good fit for you. We will follow up with you via email to discuss the next steps. If you have any questions, feel free to reach out to us at taly@databased.com.`,
      hasButton: false,
      stepItems: []
    }
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default AssessmentResultPresenter
