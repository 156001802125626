import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../../localCommon/constant'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import CandidateDto from '../CandidateRow/Presenters/CandidateDto'
import {
  highestScoreSortOptionId,
  leastRecentAssessmentSortOptionId,
  lowestScoreSortOptionId,
  mostRecentAssessmentSortOptionId
} from '../Filter/CandidatePoolFilterPresenter'
import ICandidatePoolFilterPresenter from '../Filter/InterfacesAndDtos/ICandidatePoolFilterPresenter'
import ICandidatePoolPresenter from './ICandidatePoolPresenter'
import AssessmentResultDto from './InterfacesAndDtos/AssessmentResultDto'
import CandidatePoolDto from './InterfacesAndDtos/CandidatePoolDto'

export const mockCandidateId = 148
class CandidatePoolPresenter implements ICandidatePoolPresenter {
  public isLoading: boolean
  private candidates: CandidatePoolDto[]
  private view: ISubscribingView | null
  private showCandidateDetails: boolean
  private searchQueryString: string

  constructor(
    private readonly fetcher: IFetcher,
    private readonly filterPresenter: ICandidatePoolFilterPresenter
  ) {
    this.showCandidateDetails = false
    this.view = null
    this.isLoading = false
    this.candidates = []
    this.searchQueryString = ''
  }

  public getFilterPresenter(): ICandidatePoolFilterPresenter {
    return this.filterPresenter
  }

  public async initialize(): Promise<void> {
    this.isLoading = true
    const result = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}candidates`,
      body: null
    })

    if (Array.isArray(result)) {
      this.candidates = result.sort((a, b) => {
        return this.getMostRecentScoreFromAssessments(a.assessments) >
          this.getMostRecentScoreFromAssessments(b.assessments)
          ? -1
          : 1
      })
    }

    await this.filterPresenter.initialize()
    this.isLoading = false
    this.updateView()
  }

  public getCandidates(): CandidatePoolDto[] {
    return this.candidates
  }

  public getFilteredCandidates(): CandidatePoolDto[] {
    const scoreFilters = this.filterPresenter
      .getScoreFilters()
      .filter((scoreFilter) => scoreFilter.isChecked)

    const roleFilters = this.filterPresenter
      .getRoleFilters()
      .filter((roleFilter) => roleFilter.isChecked)

    const workPreferences = this.filterPresenter
      .getWorkSituationFilters()
      .filter((filter) => filter.isChecked)

    let filteredCandidates = this.candidates

    if (scoreFilters.length) {
      filteredCandidates = filteredCandidates.filter((candidate) => {
        return scoreFilters.every((filter) => {
          const value = parseFloat(filter.id)
          return this.getMostRecentScoreFromAssessments(candidate.assessments) >= value
        })
      })
    }

    if (roleFilters.length) {
      filteredCandidates = filteredCandidates.filter((candidate) => {
        return roleFilters.some((filter) => candidate.roleInterestedIn === filter.label)
      })
    }

    if (workPreferences.length) {
      filteredCandidates = filteredCandidates.filter((candidate) => {
        return workPreferences.some((filter) => {
          // @ts-ignore the id of the work preferences are set to the property names of this dto
          return candidate[filter.id] === true
        })
      })
    }

    if (this.searchQueryString !== '') {
      filteredCandidates = filteredCandidates.filter((candidate) => {
        return candidate.name.toLowerCase().includes(this.searchQueryString.toLowerCase())
      })
    }

    if (this.filterPresenter.getUniversitySearchInput() !== '') {
      const universitySearchInput = this.filterPresenter.getUniversitySearchInput()
      filteredCandidates = filteredCandidates.filter((candidate) => {
        return candidate.assessments.some((assessment) =>
          assessment.scoreDto.prompts.some(
            (prompt) => prompt.response.responseValue === universitySearchInput
          )
        )
      })
    }

    return this.sortCandidatesBySelectedOption(filteredCandidates)
  }

  private sortCandidatesBySelectedOption(candidatesParam: CandidatePoolDto[]): CandidatePoolDto[] {
    const selectedSortOption = this.filterPresenter.getSelectSortOrderOption()
    const candidates = [...candidatesParam]
    if (selectedSortOption === highestScoreSortOptionId) {
      return candidates.sort((a, b) => {
        return this.getMostRecentScoreFromAssessments(a.assessments) >
          this.getMostRecentScoreFromAssessments(b.assessments)
          ? -1
          : 1
      })
    }
    if (selectedSortOption === lowestScoreSortOptionId) {
      return candidates.sort((a, b) => {
        return this.getMostRecentScoreFromAssessments(a.assessments) >
          this.getMostRecentScoreFromAssessments(b.assessments)
          ? 1
          : -1
      })
    }
    if (selectedSortOption === mostRecentAssessmentSortOptionId) {
      return candidates.sort((a, b) => {
        const aAssessment = this.getMostRecentAssessmentDto(a.assessments)
        const bAssessment = this.getMostRecentAssessmentDto(b.assessments)

        if (!aAssessment && !bAssessment) {
          return 0 // Both are null, so they're equal
        }
        if (!aAssessment) {
          return 1 // Null values are considered "less recent"
        }
        if (!bAssessment) {
          return -1 // Null values are considered "less recent"
        }

        // Compare the timestamps if both are non-null
        return (
          new Date(bAssessment.scoreCalculationTimestamp).getTime() -
          new Date(aAssessment.scoreCalculationTimestamp).getTime()
        )
      })
    }
    if (selectedSortOption === leastRecentAssessmentSortOptionId) {
      return candidates.sort((a, b) => {
        const aAssessment = this.getMostRecentAssessmentDto(a.assessments)
        const bAssessment = this.getMostRecentAssessmentDto(b.assessments)

        if (!aAssessment && !bAssessment) {
          return 0 // Both are null, so they're equal
        }
        if (!aAssessment) {
          return -1 // Null values are considered "less recent"
        }
        if (!bAssessment) {
          return 1 // Null values are considered "less recent"
        }

        // Compare the timestamps if both are non-null
        return (
          new Date(aAssessment.scoreCalculationTimestamp).getTime() -
          new Date(bAssessment.scoreCalculationTimestamp).getTime()
        )
      })
    }

    return candidates
  }

  private getMostRecentAssessmentDto(
    assessments: AssessmentResultDto[]
  ): AssessmentResultDto | null {
    const sortedAssessments = assessments.sort((a, b) => {
      return new Date(a.scoreCalculationTimestamp).getTime() >
        new Date(b.scoreCalculationTimestamp).getTime()
        ? 1
        : -1
    })

    return sortedAssessments?.[0] ?? null
  }

  private getMostRecentScoreFromAssessments(assessments: AssessmentResultDto[]): number {
    const mostRecentAssessment = this.getMostRecentAssessmentDto(assessments)

    return mostRecentAssessment?.scoreDto?.averageScore ?? -1
  }

  public getCandidate(userId: number): CandidateDto {
    return {
      shouldShowCandidateDetails: this.showCandidateDetails,
      id: userId,
      level: 'AE 5',
      range: '$10K - $20K',
      rank: 'Top 3%'
    }
  }

  public shouldShowCandidateDetails(): boolean {
    return this.showCandidateDetails
  }

  public showCandidateInfo(): void {
    this.showCandidateDetails = true
    this.updateView()
  }

  public hideCandidateInfo(): void {
    this.showCandidateDetails = false
    this.updateView()
  }

  public getSearchQueryString(): string {
    return this.searchQueryString
  }

  public handleSearchQuery(query: string): void {
    this.searchQueryString = query
    this.updateView()
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }
}

export default CandidatePoolPresenter
