import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../../localCommon/constant'
import DateHelper from '../../../localCommon/DateHelper/DateHelper'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import JobDto from '../Dtos/JobDto'
import ApplicationWithNameDto from './Interfaces/ApplicationWithNameDto'
import IJobListingsPresenter from './Interfaces/IJobListingsPresenter'

class JobListingsPresenter implements IJobListingsPresenter {
  private view: ISubscribingView | null
  private loading: boolean
  private jobListings: JobDto[]
  private showApplicantsForJobWindow: boolean
  private applicantsForSelectedJob: ApplicationWithNameDto[]
  private selectedJobId: number

  constructor(private readonly fetcher: IFetcher) {
    this.view = null
    this.loading = true
    this.jobListings = []
    this.showApplicantsForJobWindow = false
    this.applicantsForSelectedJob = []
    this.selectedJobId = -1
  }

  public async initialize(): Promise<void> {
    const result = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}jobs`,
      body: null
    })

    if (this.isValidJobDtosResponse(result)) {
      this.jobListings = result
    }

    this.loading = false
    this.updateView()
  }

  public getJobCompensationRange(job: JobDto): string {
    if (job) {
      return `$${(job.baseCompensationLow + job.variableCompensation).toLocaleString()} - $${(job.baseCompensationHigh + job.variableCompensation).toLocaleString()}`
    }
    return ''
  }

  private isValidJobDtosResponse(result: any): boolean {
    return Array.isArray(result)
  }

  public async clickedViewApplicantsForJob(jobId: number): Promise<void> {
    this.showApplicantsForJobWindow = true

    const result = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}job/${jobId}/applicants`,
      body: null
    })

    this.selectedJobId = jobId
    if (Array.isArray(result)) {
      this.applicantsForSelectedJob = result
    }

    this.updateView()
  }

  public closeApplicantsForJobWindow(): void {
    this.showApplicantsForJobWindow = false
    this.applicantsForSelectedJob = []
    this.selectedJobId = -1
    this.updateView()
  }

  public getSelectedJobTitle(): string {
    const job = this.jobListings.find((job) => job.id === this.selectedJobId)
    if (job) {
      return `${job.companyName} - ${job.jobTitle}`
    }
    return ''
  }

  public getApplicantsForSelectedJob(): ApplicationWithNameDto[] {
    return this.applicantsForSelectedJob
      .sort((a, b) => {
        return new Date(a.applicationDate).getTime() > new Date(b.applicationDate).getTime()
          ? -1
          : 1
      })
      .map((application) => {
        return {
          ...application,
          applicationDate: DateHelper.getDDMONYYYYDateString(new Date(application.applicationDate))
        }
      })
  }

  public shouldShowApplicantsForJobWindow(): boolean {
    return this.showApplicantsForJobWindow
  }

  public isLoading(): boolean {
    return this.loading
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public getJobListings(): JobDto[] {
    return this.jobListings
  }
}

export default JobListingsPresenter
