import './style.scss'

import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../../localCommon/Interfaces/ISubscribingView'
import LoadingSpinner from '../../../../localCommon/LoadingSpinner/LoadingSpinner'
import MetricDetail from '../../CoreAssessments/MetricDetail/MetricDetail'
import IHomePresenter from '../../Presenters/Interfaces/IHomePresenter'
import AssessmentResult from './AssessmentResult'
import IAssessmentResultPresenter from './Presenter/IAssessmentResultPresenter'

type Props = {
  homePresenter: IHomePresenter
  presenter: IAssessmentResultPresenter
}

class AssessmentResultWrapper extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
    this.props.presenter.initialize()
  }

  componentDidUpdate(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { homePresenter, presenter } = this.props

    if (presenter.shouldShowMetricDetail()) {
      return <MetricDetail presenter={presenter} />
    }

    if (presenter.isLoadingMetrics()) {
      return <LoadingSpinner />
    }
    return <AssessmentResult homePresenter={homePresenter} presenter={presenter} />
  }

  update = (): void => {
    this.setState({})
  }
}

export default AssessmentResultWrapper
