import './style.scss'

import React, { Component, ReactElement } from 'react'

import images from '../../../images'
import ThemeButton from '../../../localCommon/Button/ThemeButton/ThemeButton'
import CheckBox from '../../../localCommon/CheckBox/CheckBox'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import OutlinedInput from '../../../localCommon/OutlinedInput/OutlinedInput'
import IVisibilityManager from '../../../localCommon/SlidingMenu/VisibilityManager/IVisibilityManager'
import ICandidatePoolFilterPresenter from './InterfacesAndDtos/ICandidatePoolFilterPresenter'

type Props = {
  presenter: ICandidatePoolFilterPresenter
  visibilityManager: IVisibilityManager
}

class CandidateFilter extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { visibilityManager, presenter } = this.props
    const scoreFilters = presenter.getScoreFilters()
    const roleFilters = presenter.getRoleFilters()
    const workPreferences = presenter.getWorkSituationFilters()
    const sortOptions = presenter.getSortOrderOptions()
    const universityInput = presenter.getUniversitySearchInput()
    const universities = presenter.getUniversities()

    return (
      <div className={`filter ${visibilityManager.isOpen() ? 'open' : ''}`}>
        <div className='filter-header'>
          <img
            alt='close-drawer-icon'
            className='menu-close'
            onClick={this.closeMenu}
            src={images.closeIcon}
          />
          <div className='title-bold'>Filters</div>
        </div>
        <div className='filter-content'>
          <div>
            <p className='body1'>Role Type</p>
            {roleFilters.map((dto, idx) => {
              return (
                <CheckBox
                  key={idx}
                  id={dto.id}
                  label={dto.label}
                  checked={dto.isChecked}
                  onChange={this.handleRoleCheckBoxChange}
                />
              )
            })}
          </div>
          <div>
            <p className='body1'>Score</p>
            {scoreFilters.map((dto, idx) => {
              return (
                <CheckBox
                  key={idx}
                  id={dto.id}
                  label={dto.label}
                  checked={dto.isChecked}
                  onChange={this.handleScoreCheckBoxChange}
                />
              )
            })}
          </div>
          <p className='body1'>Work Preferences</p>
          <div className='filter-work-preferences'>
            {workPreferences.map((dto, idx) => {
              return (
                <CheckBox
                  key={idx}
                  id={dto.id}
                  label={dto.label}
                  checked={dto.isChecked}
                  onChange={this.handleWorkPreferenceCheckBoxChange}
                />
              )
            })}
          </div>
          <p className='body1'>University Search</p>
          <div className='filter-university-search'>
            <OutlinedInput
              type='text'
              value={universityInput}
              onChange={this.handleUniversityInputChange}
              id='fuzzy-search'
              isRequired={false}
              caption={''}
              error={''}
              shouldShowErrorMessage={false}
              label={'Search for University'}
            />
            {universityInput && universities.length > 0 && (
              <ul className='university-list'>
                {universities.map((university: string, index: number) => (
                  <li
                    key={index}
                    className='university-item'
                    onClick={() => this.handleUniversityClick(university)}
                  >
                    {university}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <p className='body1'>Sort Order</p>
          <div className='filter-sort-order'>
            {sortOptions.map((dto, idx) => {
              return (
                <CheckBox
                  key={idx}
                  id={dto.id}
                  label={dto.label}
                  checked={dto.isChecked}
                  onChange={this.handleSortOrderCheckBoxChange}
                />
              )
            })}
          </div>
        </div>
        <div
          className={`filter-footer ${visibilityManager.isOpen() ? 'open' : ''}`}
          style={{ bottom: `${this.isMobileSafari() ? '80px' : '0'}` }}
        >
          <ThemeButton onClick={this.filterCandidates} type='button'>
            See candidates
          </ThemeButton>
        </div>
      </div>
    )
  }

  handleRoleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    this.props.presenter.setRoleFilters(id)
  }

  handleScoreCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    this.props.presenter.setScoreFilters(id)
  }

  handleWorkPreferenceCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    this.props.presenter.setWorkSituationFilters(id)
  }

  handleSortOrderCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    this.props.presenter.setSortOrderFilters(id)
  }

  handleUniversityInputChange = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement
    this.props.presenter.updateUniversitySearchInput(target.value)
  }

  handleUniversityClick = (university: string): void => {
    this.props.presenter.clickedUniversity(university)
  }

  isMobileSafari = (): boolean => {
    const { userAgent } = window.navigator

    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
    const isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent)
    return isIOS && isSafari
  }

  filterCandidates = (): void => {
    this.closeMenu()
  }

  closeMenu = (): void => {
    this.props.visibilityManager.close()
  }

  update = (): void => {
    this.setState({})
  }
}

export default CandidateFilter
