import Cookies from 'js-cookie'

import IFetcher from '../../../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../../../localCommon/constant'
import { userIdKey } from '../../../../localCommon/CookieKeys'
import ISubscribingView from '../../../../localCommon/Interfaces/ISubscribingView'
import IAssessmentStore from '../../../../Models/Interfaces/IAssessmentStore'
import AssessmentResultPresenterFactory from '../AssessmentResult/Presenter/AssessmentResultPresenterFactory'
import IAssessmentResultPresenter from '../AssessmentResult/Presenter/IAssessmentResultPresenter'
import assessmentIdToStep from '../DashboardSteps'
import IAssessmentHandler from '../NextReadyToTakeAssessmentProvider/INextReadyToTakeAssessmentProvider'
import NextStepDto from '../NextStepDto'
import AssessmentIdAndStatus from './AssessmentIdAndStatus'
import IDashboardPresenter from './IDashboardPresenter'

class DashboardPresenter implements IDashboardPresenter {
  private loading: boolean
  private step: number
  private view: ISubscribingView | null
  public assessment: AssessmentIdAndStatus | null

  constructor(
    private readonly assessmentHandler: IAssessmentHandler,
    private readonly assessmentStatusProvider: IAssessmentStore,
    private readonly fetcher: IFetcher
  ) {
    this.step = this.assessmentStatusProvider.currentAssessmentId
    this.loading = true
    this.view = null
    this.assessment = null
  }

  public getAssessmentResultPresenter(): IAssessmentResultPresenter {
    const userId = this.getUserId()
    return AssessmentResultPresenterFactory.buildPresenter(
      userId,
      this.assessment?.assessmentId ?? -1
    )
  }

  private getUserId(): number {
    return parseInt(Cookies.get(userIdKey) ?? '', 10) ?? null
  }

  public shouldDisplayDashboard(): boolean {
    return this.assessment?.hasFinishedAssessment ?? true
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public updateInProgressAssessmentId(stepId: number): void {
    this.assessmentStatusProvider.updateInProgressAssessmentId(stepId)
    this.updateView()
  }

  public async initialize(): Promise<void> {
    if (this.step === -1) {
      this.step = await this.assessmentHandler.getIdOfNextAssessmentToTake()
    }
    const userId = this.getUserId()

    const res = await this.fetcher.fetch({
      method: 'GET',
      url: `${serverBaseUrl}/user/${userId}/assessment`,
      body: null
    })

    this.assessment = res?.assessmentId ? res : null

    this.loading = false

    this.updateView()
  }

  public getAssessmentId(): number {
    return this.step
  }

  public isLoading(): boolean {
    return this.loading
  }

  public getStep(): NextStepDto | null {
    return assessmentIdToStep.get(this.step) ?? null
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }
}

export default DashboardPresenter
