import './style.scss'

import React, { Component, ReactElement } from 'react'

import IAssessmentResultPresenter from '../Dashboard/AssessmentResult/Presenter/IAssessmentResultPresenter'
import AssessmentBars from './AssessmentBars/AssessmentBars'

type Props = {
  presenter: IAssessmentResultPresenter
}

class CoreAssessments extends Component<Props> {
  render(): ReactElement {
    const { presenter } = this.props

    if (presenter.isLoadingMetrics()) {
      return (
        <div className='coreAssessments section-with-border'>
          <p className='coreAssessments-title subtitle2'>Assessment scores</p>
          <p className='loading' />
          <p className='loading' />
          <p className='loading' />
          <p className='loading' />
          <p className='loading' />
        </div>
      )
    }

    return (
      <div className='coreAssessments section-with-border'>
        <p className='coreAssessments-title subtitle2'>Assessment scores</p>
        <AssessmentBars presenter={presenter} shouldShowDropdown />
      </div>
    )
  }
}

export default CoreAssessments
