import './style.scss'

import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../../localCommon/Interfaces/ISubscribingView'
import IAssessmentResultPresenter from './Presenter/IAssessmentResultPresenter'

export const assessmentCompletionBox = 'assessmentResult-completeBox'

type Props = {
  presenter: IAssessmentResultPresenter
}

class AssessmentMessageBox extends Component<Props> implements ISubscribingView {
  render(): ReactElement {
    const message = this.props.presenter.getMessageToDisplay()

    if (!message) {
      return (
        <div className='assessmentResult-loadingMessageBox'>
          <div className='assessmentResult-boxContent' />
        </div>
      )
    }

    return (
      <div className='assessmentResult-completeBox' id={assessmentCompletionBox}>
        <div className='assessmentResult-boxContent'>
          <div className='assessmentResult-nav'>
            <p className='subtitle2'>{message.header}</p>
          </div>
          <p className='headline5'>{message.title}</p>
          <p className='body2'>{message.content}</p>
        </div>
      </div>
    )
  }

  update = (): void => {
    this.setState({})
  }
}

export default AssessmentMessageBox
