import './style.scss'

import React, { Component, ReactElement } from 'react'
import ReactQuill from 'react-quill'

import images from '../../../images'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import LoadingSpinner from '../../../localCommon/LoadingSpinner/LoadingSpinner'
import ICandidateViewJobPresenter from './ICandidateViewJobPresenter'

type Props = {
  presenter: ICandidateViewJobPresenter
}

class CandidateViewJob extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {}

  render(): ReactElement {
    const { presenter } = this.props
    const job = presenter.selectedJobDto
    const hasAppliedToJob = presenter.hasUserAppliedToJobSelectedJob()
    const showModal = presenter.isModalOpen()
    const jobStatus = presenter.getJobStatus()

    if (presenter.isLoading()) {
      return <LoadingSpinner />
    }
    return (
      <div className='candidate-view-job slide-in'>
        <img
          alt='close-drawer-icon'
          className='menu-close'
          onClick={this.handleCloseClick}
          src={images.closeIcon}
        />
        <div className='job-details'>
          <h3 className='card-title'>{job?.jobTitle}</h3>
          <p className='card-salary'>OTE: {presenter.getOTERange()}</p>
          <p className='card-location'>{`${job?.location} · ${job?.jobType}`}</p>
          <div className='experience-container'>
            <p className='card-experience-level'>
              Experience Level: {presenter.getExperienceLevel()}
            </p>
            <button
              className={`interested-button ${hasAppliedToJob ? 'disabled' : ''}`}
              onClick={this.handleInterestedClick}
              type='button'
              disabled={hasAppliedToJob}
            >
              {hasAppliedToJob ? `${presenter.getDateAppliedToJob()}` : 'Apply'}
            </button>
          </div>
          {jobStatus && <span className='card-job-status'>{jobStatus}</span>}
          <div className='gradient-line' />
          <div className='card-description'>
            <h2 className='card-description-title'>Job Description</h2>
            <ReactQuill value={job?.jobDescription} readOnly theme='bubble' />
          </div>
        </div>
        {showModal && this.renderModal()}
      </div>
    )
  }

  update(): void {
    this.setState({})
  }

  handleCloseClick = (): void => {
    this.props.presenter.closeJobView()
  }

  handleInterestedClick = (): void => {
    const { presenter } = this.props
    if (!presenter.hasUserAppliedToJobSelectedJob()) {
      presenter.interestedInJob()
    }
  }

  closeModal = (): void => {
    this.props.presenter.closeModal()
  }

  handleCreateAccountClick = (): void => {
    this.props.presenter.createAccount()
  }

  renderModal(): ReactElement {
    return (
      <div className='modal-overlay'>
        <div className='modal'>
          <div className='modal-header'>
            <span className='modal-close' onClick={this.closeModal}>
              &times;
            </span>
          </div>
          <div className='modal-body'>
            <p>In order to apply for this job, you must create a DataBased account.</p>
            <button
              type='button'
              className='create-account-button'
              onClick={this.handleCreateAccountClick}
            >
              Create Account
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default CandidateViewJob
