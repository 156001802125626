import './style.scss' // Import the SCSS file

import React, { Component, ReactElement } from 'react'

import images from '../../../images'
import Header from '../../../localCommon/HeaderWithProfile/Header'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import LoadingSpinner from '../../../localCommon/LoadingSpinner/LoadingSpinner'
import MenuVisibilityManager from '../../../localCommon/SlidingMenu/MenuVisibilityManager/MenuVisibilityManager'
import JobDto from '../Dtos/JobDto'
import CandidateViewJob from './CandidateViewJob'
import CandidateViewJobsFilter from './CandidateViewJobsFilter'
import ICandidateViewJobsPresenter from './ICandidateViewJobOverviewCard'
import ICandidateViewJobPresenter from './ICandidateViewJobPresenter'
import ICandidateViewJobsFilterPresenter from './ICandidateViewJobsFilterPresenter'
import JobOverviewCard from './JobOverviewCard'

type Props = {
  menuVisibilityManager: MenuVisibilityManager
  presenter: ICandidateViewJobsPresenter &
    ICandidateViewJobsFilterPresenter &
    ICandidateViewJobPresenter
}

class CandidateViewJobs extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
    this.props.presenter.initialize()
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { presenter, menuVisibilityManager } = this.props

    if (presenter.isLoading()) {
      return <LoadingSpinner />
    }

    return (
      <div className='candidate-view-jobs'>
        {presenter.showFilter && <CandidateViewJobsFilter presenter={presenter} />}
        {presenter.showJob && <CandidateViewJob presenter={presenter} />}
        <div className='job-board-container'>
          <Header menuVisibilityManager={menuVisibilityManager} />
          {this.renderJobBoard()}
        </div>
      </div>
    )
  }

  update(): void {
    this.setState({})
  }

  handleFilterClick = (): void => {
    this.props.presenter.toggleFilterView()
  }

  handleJobClick = (jobId: number): void => {
    this.props.presenter.clickOnJob(jobId)
  }

  private renderJobBoard(): ReactElement {
    const { presenter } = this.props
    const jobs = presenter.getJobDtos()
    const jobsAppliedTo = presenter.getJobsAppliedTo()
    const openJobsTitle = 'Open Jobs'
    const jobsAppliedToTitle = "Jobs I've Applied to"

    return (
      <div>
        <div className='controls-container'>
          <img
            alt='filter'
            className='jobs-filter'
            src={images.filter}
            onClick={this.handleFilterClick}
          />
          <h2 className='jobs-title'>
            {jobsAppliedTo.length ? jobsAppliedToTitle : openJobsTitle}
          </h2>
        </div>
        <div className='jobs-container'>
          {jobsAppliedTo.length > 0 &&
            jobsAppliedTo.map((job: JobDto) => {
              return (
                <div key={job.id} onClick={() => this.handleJobClick(job.id)}>
                  <JobOverviewCard
                    presenter={presenter.getJobOverviewPresenter(job)}
                    key={job.id}
                  />
                </div>
              )
            })}
        </div>
        {jobsAppliedTo.length > 0 && <h2 className='jobs-title'>Open Jobs</h2>}
        <div className='jobs-container'>
          {jobs.map((job: JobDto) => {
            return (
              <div key={job.id} onClick={() => this.handleJobClick(job.id)}>
                <JobOverviewCard presenter={presenter.getJobOverviewPresenter(job)} key={job.id} />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default CandidateViewJobs
