import AssessmentResultAdaptor from '../../../../../Adaptors/AssessmentResultAdaptor/AssessmentResultAdaptor'
import AssessmentScoreFetcher from '../../../../../APIs/AssessmentScoreFetcher/AssessmentScoreFetcher'
import Fetcher from '../../../../../Drivers/Fetcher'
import AssessmentResultHandler from '../../../../../Handlers/AssessmentResultHandler/AssessmentResultHandler'
import AssessmentScoreCalculator from '../../../../../Handlers/AssessmentScoreCalculator/AssessmentScoreCalculator'
import JobOverviewCardPresenterFactory from '../../../../Jobs/CandidateView/JobOverviewCardPresenterFactory'
import AssessmentResultPresenter from './AssessmentResultPresenter'
import IAssessmentResultPresenter from './IAssessmentResultPresenter'

class AssessmentResultPresenterFactory {
  public static buildPresenter(userId: number, assessmentId: number): IAssessmentResultPresenter {
    return new AssessmentResultPresenter(
      new AssessmentResultHandler(
        new AssessmentResultAdaptor(new AssessmentScoreCalculator()),
        new Fetcher()
      ),
      new AssessmentScoreFetcher(new Fetcher(), userId),
      assessmentId,
      userId,
      new Fetcher(),
      new JobOverviewCardPresenterFactory()
    )
  }
}

export default AssessmentResultPresenterFactory
