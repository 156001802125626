import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import JobDto from '../Dtos/JobDto'
import ApplicationDto from '../Presenters/Interfaces/ApplicationDto'
import IJobOverviewCardPresenter from './IJobOverviewCardPresenter'

class JobOverviewCardPresenter implements IJobOverviewCardPresenter {
  private view: ISubscribingView | null
  constructor(
    private readonly job: JobDto,
    private readonly applicationDto: ApplicationDto | null
  ) {
    this.view = null
  }

  public getJob(): JobDto {
    return this.job
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public getOTERange(): string {
    if (this.job === null) {
      return ''
    }
    const low = this.job.baseCompensationLow + this.job.variableCompensation
    const high = this.job.baseCompensationHigh + this.job.variableCompensation
    return `$${low.toLocaleString()} - $${high.toLocaleString()}`
  }

  public getApplicationStatus(): string | null {
    if (this.applicationDto === null) {
      return null
    }
    return `Status: ${this.applicationDto.status}`
  }
}

export default JobOverviewCardPresenter
