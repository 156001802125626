import './style.scss' // Import the SCSS file

import React, { Component, ReactElement } from 'react'

import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import IJobOverviewCardPresenter from './IJobOverviewCardPresenter'

type Props = {
  presenter: IJobOverviewCardPresenter
}

class JobOverviewCard extends Component<Props> implements ISubscribingView {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  render(): ReactElement {
    const { presenter } = this.props
    const job = presenter.getJob()
    const applicationStatus = presenter.getApplicationStatus()

    return (
      <div className='job-card'>
        <div className='job-card-content'>
          <h3 className='job-title'>{job.jobTitle}</h3>
          <p className='job-location'>{job.location}</p>
          <p className='job-salary'>OTE: {presenter.getOTERange()}</p>
          {applicationStatus && <span className='card-job-status'>{applicationStatus}</span>}
        </div>
        <button className='view-button' type='button'>
          View
        </button>
      </div>
    )
  }

  update(): void {
    this.setState({})
  }
}

export default JobOverviewCard
