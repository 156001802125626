import Cookies from 'js-cookie'

import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../../localCommon/constant'
import {
  assessmentIdKey,
  authTokenKey,
  jobUrlKey,
  userIdKey
} from '../../../localCommon/CookieKeys'
import DateHelper from '../../../localCommon/DateHelper/DateHelper'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import RadioButtonDto from '../../../localCommon/RadioButton/RadioButtonDto'
import RecruitingRoleTypeDto from '../../CandidatePool/Candidate/InterfacesAndDtos/RecruitingRoleTypeDto'
import CheckBoxDto from '../../CandidatePool/Filter/InterfacesAndDtos/CheckBoxDto'
import {
  candidateViewJobBoardRoute,
  candidateViewJobRoute,
  ssoLoginsRoute
} from '../../RouteConstants'
import JobDto from '../Dtos/JobDto'
import ApplicationDto from '../Presenters/Interfaces/ApplicationDto'
import ICandidateViewJobsPresenter from './ICandidateViewJobOverviewCard'
import ICandidateViewJobPresenter from './ICandidateViewJobPresenter'
import ICandidateViewJobsFilterPresenter from './ICandidateViewJobsFilterPresenter'
import IJobOverviewCardPresenter from './IJobOverviewCardPresenter'
import JobOverviewCardPresenter from './JobOverviewCardPresenter'

export const noMinimumOTE = 'No Minimum OTE'

class CandidateViewJobsPresenter
  implements
    ICandidateViewJobsPresenter,
    ICandidateViewJobsFilterPresenter,
    ICandidateViewJobPresenter
{
  private view: ISubscribingView | null
  private loading: boolean
  private jobDtos: JobDto[]
  public showFilter: boolean
  private recruitingRoleTypeDtos: RecruitingRoleTypeDto[]
  private recruitingRoleTypeFilters: CheckBoxDto[]
  private oteFilters: RadioButtonDto[]
  private workSituationFilters: CheckBoxDto[]
  public showJob: boolean
  public selectedJobDto: JobDto | null
  private userJobIdsAppliedTo: number[]
  private userApplications: ApplicationDto[]
  private hasADataBasedAccount: boolean
  private modalViewState: boolean

  constructor(
    private readonly fetcher: IFetcher,
    private givenJobId: number | undefined | null = null
  ) {
    this.modalViewState = false
    this.selectedJobDto = null
    this.view = null
    this.loading = false
    this.jobDtos = []
    this.showFilter = false
    this.showJob = false
    this.recruitingRoleTypeDtos = []
    this.recruitingRoleTypeFilters = []
    this.userJobIdsAppliedTo = []
    this.userApplications = []
    this.hasADataBasedAccount = Cookies.get(authTokenKey) !== undefined
    this.modalViewState = false

    this.oteFilters = [
      {
        id: 0,
        name: noMinimumOTE,
        orderIndex: -1,
        isChecked: true
      },
      {
        id: 1,
        name: '60000',
        orderIndex: 0,
        isChecked: false
      },
      {
        id: 2,
        name: '80000',
        orderIndex: 1,
        isChecked: false
      },
      {
        id: 3,
        name: '100000',
        orderIndex: 2,
        isChecked: false
      },
      {
        id: 4,
        name: '125000',
        orderIndex: 3,
        isChecked: false
      },
      {
        id: 5,
        name: '150000',
        orderIndex: 4,
        isChecked: false
      },
      {
        id: 6,
        name: '200000',
        orderIndex: 5,
        isChecked: false
      },
      {
        id: 7,
        name: '250000',
        orderIndex: 6,
        isChecked: false
      }
    ]
    this.workSituationFilters = [
      {
        label: 'On-site',
        isChecked: false,
        id: '1'
      },
      {
        label: 'Hybrid',
        isChecked: false,
        id: '2'
      },
      {
        label: 'Remote',
        isChecked: false,
        id: '3'
      }
    ]
  }

  public async initialize(): Promise<void> {
    this.loading = true
    this.updateView()

    const jobsResult = await this.fetcher.fetch({
      url: `${serverBaseUrl}jobs`,
      method: 'GET',
      body: null
    })

    if (this.isValidJobDtos(jobsResult)) {
      this.jobDtos = jobsResult
    }

    const rolesResult = await this.fetcher.fetch({
      url: `${serverBaseUrl}recruitingRoleTypes`,
      method: 'GET',
      body: null
    })
    if (this.isValidRolesResponse(rolesResult)) {
      this.recruitingRoleTypeDtos = rolesResult
      this.setRecruitingRoleFilters(rolesResult)
    }

    const userApplicationsResult = await this.fetcher.fetch({
      body: null,
      method: 'GET',
      url: `${serverBaseUrl}user/${Cookies.get(userIdKey)}/applications`
    })
    this.setUserJobIdsAppliedTo(userApplicationsResult)

    if (this.givenJobId) {
      this.selectedJobDto = this.jobDtos.find((job) => job.id === this.givenJobId) ?? null
      if (this.selectedJobDto) {
        this.showJob = true
        const url = `${window.location.origin}${candidateViewJobBoardRoute}`
        window.history.replaceState(null, '', url)
      }
      this.givenJobId = null
    }

    this.loading = false
    this.updateView()
  }

  private isValidRolesResponse(result: any): boolean {
    return Array.isArray(result)
  }

  private setRecruitingRoleFilters(roles: RecruitingRoleTypeDto[]): void {
    roles.forEach((role: RecruitingRoleTypeDto) => {
      this.recruitingRoleTypeFilters.push({
        label: `${role.role} (${role.description})`,
        isChecked: false,
        id: String(role.id)
      })
    })
  }

  private setUserJobIdsAppliedTo(result: any): void {
    if (Array.isArray(result)) {
      this.userJobIdsAppliedTo = result.map((res) => res.jobId)
      this.userApplications = result
    }
  }

  public hasUserAppliedToJobSelectedJob(): boolean {
    if (this.selectedJobDto?.id) {
      return this.userJobIdsAppliedTo.includes(this.selectedJobDto.id)
    }

    return false
  }

  public getOTERange(): string {
    if (this.selectedJobDto === null) {
      return ''
    }
    const low = this.selectedJobDto.baseCompensationLow + this.selectedJobDto.variableCompensation
    const high = this.selectedJobDto.baseCompensationHigh + this.selectedJobDto.variableCompensation
    return `$${low.toLocaleString()} - $${high.toLocaleString()}`
  }

  public toggleFilterView(): void {
    this.showFilter = !this.showFilter
    this.updateView()
  }

  public getJobDtos(): JobDto[] {
    return this.filterJobsByWorkSituationFilters(
      this.filterJobsByOTEFilters(this.filterJobsByRoleFilters(this.jobDtos))
    ).filter((job) => this.userJobIdsAppliedTo.includes(job.id) === false)
  }

  public getJobsAppliedTo(): JobDto[] {
    return this.jobDtos.filter((job) => this.userJobIdsAppliedTo.includes(job.id))
  }

  public getJobOverviewPresenter(dto: JobDto): IJobOverviewCardPresenter {
    return new JobOverviewCardPresenter(
      dto,
      this.userApplications.find((job) => job.jobId === dto.id) ?? null
    )
  }

  private isValidJobDtos(result: any): boolean {
    return Array.isArray(result)
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public isLoading(): boolean {
    return this.loading
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  public getRoleTypeFilters(): CheckBoxDto[] {
    return this.recruitingRoleTypeFilters
  }

  public setRoleFilters(id: string): void {
    for (let i = 0; i < this.recruitingRoleTypeFilters.length; i++) {
      const filter = this.recruitingRoleTypeFilters[i]
      if (filter.id === id) {
        this.recruitingRoleTypeFilters[i].isChecked = !this.recruitingRoleTypeFilters[i].isChecked
      }
    }
    this.updateView()
  }

  private filterJobsByRoleFilters(jobs: JobDto[]): JobDto[] {
    if (this.recruitingRoleTypeFilters.every((filter) => filter.isChecked === false)) {
      return jobs
    }

    return jobs.filter((job) => {
      const filterForJob = this.recruitingRoleTypeFilters.find((filter) => {
        return Number.parseInt(filter.id, 10) === job.recruitingRoleTypeId
      })

      if (filterForJob) {
        return filterForJob.isChecked
      }
      return false
    })
  }

  public getOTEFilters(): RadioButtonDto[] {
    return this.oteFilters
  }

  public selectOTEFilter(id: number): void {
    let idxOfSelectedFilter = -1
    for (let i = 0; i < this.oteFilters.length; i++) {
      if (this.oteFilters[i].id === id) {
        idxOfSelectedFilter = i
      }
      this.oteFilters[i].isChecked = false
    }
    this.oteFilters[idxOfSelectedFilter].isChecked = !this.oteFilters[idxOfSelectedFilter].isChecked
    this.updateView()
  }

  private filterJobsByOTEFilters(jobs: JobDto[]): JobDto[] {
    const filter = this.oteFilters.find((filter) => {
      return filter.isChecked
    })

    if (!filter) {
      return jobs
    }

    if (filter?.name === noMinimumOTE) {
      return jobs
    }

    return jobs.filter((job) => {
      const filterOTE = Number.parseInt(filter.name, 10)
      const jobMaxOTE = job.baseCompensationHigh + job.variableCompensation
      return filterOTE <= jobMaxOTE
    })
  }

  public getWorkSituationFilters(): CheckBoxDto[] {
    return this.workSituationFilters
  }

  public setWorkSituationFilters(id: string): void {
    for (let i = 0; i < this.workSituationFilters.length; i++) {
      const filter = this.workSituationFilters[i]
      if (filter.id === id) {
        this.workSituationFilters[i].isChecked = !this.workSituationFilters[i].isChecked
      }
    }
    this.updateView()
  }

  private filterJobsByWorkSituationFilters(jobs: JobDto[]): JobDto[] {
    if (this.workSituationFilters.every((filter) => filter.isChecked === false)) {
      return jobs
    }

    return jobs.filter((job) => {
      const filterForJob = this.workSituationFilters.find((filter) => {
        return filter.label === job.jobType
      })

      if (filterForJob) {
        return filterForJob.isChecked
      }
      return false
    })
  }

  public clearRoleFilters(): void {
    for (let i = 0; i < this.recruitingRoleTypeFilters.length; i++) {
      this.recruitingRoleTypeFilters[i].isChecked = false
    }
    this.updateView()
  }

  public clearWorkSituationFilters(): void {
    for (let i = 0; i < this.workSituationFilters.length; i++) {
      this.workSituationFilters[i].isChecked = false
    }
    this.updateView()
  }

  public clickOnJob(jobId: number): void {
    this.showJob = true
    const job = this.jobDtos.find((job) => job.id === jobId)
    if (job) {
      this.selectedJobDto = job
    }
    this.updateView()
  }

  public closeJobView(): void {
    this.showJob = false
    this.selectedJobDto = null
    this.updateView()
  }

  public getExperienceLevel(): string {
    if (!this.selectedJobDto) {
      return ''
    }

    const role = this.recruitingRoleTypeDtos.find((role) => {
      return role.id === this.selectedJobDto?.recruitingRoleTypeId
    })

    if (!role) {
      return ''
    }

    return `${role.role} (${role.description})`
  }

  public interestedInJob(): void {
    if (this.hasADataBasedAccount) {
      window.location.pathname = `${candidateViewJobRoute}/${this.selectedJobDto?.id}/apply`
    } else {
      this.modalViewState = true
      this.updateView()
    }
  }

  public shouldShowApplyModal(): boolean {
    return !this.hasADataBasedAccount
  }

  public closeModal(): void {
    this.modalViewState = false
    this.updateView()
  }

  public isModalOpen(): boolean {
    return this.modalViewState
  }

  public createAccount(): void {
    const jobUrl = `${candidateViewJobRoute}/${this.selectedJobDto?.id}/apply`
    sessionStorage.setItem(jobUrlKey, jobUrl)
    const assessmentId = this.recruitingRoleTypeDtos.find(
      (role) => role.id === this.selectedJobDto?.recruitingRoleTypeId
    )?.assessmentId

    if (assessmentId) {
      sessionStorage.setItem(assessmentIdKey, String(assessmentId))
    }

    window.location.href = ssoLoginsRoute
  }

  public getDateAppliedToJob(): string {
    if (this.selectedJobDto) {
      const job = this.userApplications.find((job) => job.jobId === this.selectedJobDto?.id)

      if (job) {
        return `Applied | ${DateHelper.getDDMONYYYYDateString(new Date(job.applicationDate))}`
      }
    }
    return 'Applied'
  }

  public getJobStatus(): string | null {
    if (this.selectedJobDto) {
      const job = this.userApplications.find((job) => job.jobId === this.selectedJobDto?.id)

      if (job && job.status) {
        return `Status: ${job.status}`
      }
    }
    return null
  }

  public getApplications(): ApplicationDto[] {
    return this.userApplications
  }
}

export default CandidateViewJobsPresenter
