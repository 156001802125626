import JobDto from '../Dtos/JobDto'
import ApplicationDto from '../Presenters/Interfaces/ApplicationDto'
import IJobOverviewCardPresenter from './IJobOverviewCardPresenter'
import IJobOverviewCardPresenterFactory from './IJobOverviewCardPresenterFactory'
import JobOverviewCardPresenter from './JobOverviewCardPresenter'

class JobOverviewCardPresenterFactory implements IJobOverviewCardPresenterFactory {
  public buildPresenter(
    dto: JobDto,
    application: ApplicationDto | null
  ): IJobOverviewCardPresenter {
    return new JobOverviewCardPresenter(dto, application)
  }
}

export default JobOverviewCardPresenterFactory
