import IFetcher from '../../../Drivers/Interfaces/IFetcher'
import { serverBaseUrl } from '../../../localCommon/constant'
import ISubscribingView from '../../../localCommon/Interfaces/ISubscribingView'
import RecruitingRoleTypeDto from '../Candidate/InterfacesAndDtos/RecruitingRoleTypeDto'
import CheckBoxDto from './InterfacesAndDtos/CheckBoxDto'
import ICandidatePoolFilterPresenter from './InterfacesAndDtos/ICandidatePoolFilterPresenter'

export const highestScoreSortOptionId = 'highestScore'
export const lowestScoreSortOptionId = 'lowestScore'
export const mostRecentAssessmentSortOptionId = 'mostRecentAssessment'
export const leastRecentAssessmentSortOptionId = 'leastRecentAssessment'
class CandidatePoolFilterPresenter implements ICandidatePoolFilterPresenter {
  private view: ISubscribingView | null
  private scoreFilters: CheckBoxDto[]
  private roleFilters: CheckBoxDto[]
  private workSituationFilters: CheckBoxDto[]
  private sortOrderOptions: CheckBoxDto[]
  private universities: string[]
  private input: string

  constructor(private readonly fetcher: IFetcher) {
    this.view = null
    this.universities = []
    this.input = ''
    this.scoreFilters = [
      {
        label: '3.75+',
        id: '3.75',
        isChecked: false
      },
      {
        label: '3.8+',
        id: '3.8',
        isChecked: false
      },
      {
        label: '3.85+',
        id: '3.85',
        isChecked: false
      },
      {
        label: '3.9+',
        id: '3.9',
        isChecked: false
      }
    ]

    this.roleFilters = []

    this.workSituationFilters = [
      {
        label: 'Willing to work remote',
        id: 'workRemote',
        isChecked: false
      },
      {
        label: 'Willing to work 100% in office',
        id: 'workInOffice',
        isChecked: false
      },
      {
        label: 'Willing to work hybrid',
        id: 'workHybrid',
        isChecked: false
      },
      {
        label: 'Willing to relocate',
        id: 'willingToRelocate',
        isChecked: false
      }
    ]

    this.sortOrderOptions = [
      {
        label: 'Highest Score first',
        id: highestScoreSortOptionId,
        isChecked: true
      },
      {
        label: 'Lowest Score first',
        id: lowestScoreSortOptionId,
        isChecked: false
      },
      {
        label: 'Newest Assessment Takers first',
        id: mostRecentAssessmentSortOptionId,
        isChecked: false
      },
      {
        label: 'Oldest Assessment Takers first',
        id: leastRecentAssessmentSortOptionId,
        isChecked: false
      }
    ]
  }

  public async initialize(): Promise<void> {
    const recruitingTypes = await this.fetcher.fetch({
      url: `${serverBaseUrl}recruitingRoleTypes`,
      method: 'GET',
      body: null
    })

    if (Array.isArray(recruitingTypes)) {
      this.initializeRoleFilters(recruitingTypes)
    }
  }

  public getUniversities(): string[] {
    return this.universities
  }

  public clickedUniversity(university: string): void {
    this.input = university
    this.universities = []
    this.updateView()
  }

  public getUniversitySearchInput(): string {
    return this.input
  }

  public updateUniversitySearchInput(input: string): void {
    this.input = input
    this.getMatchingUniversities()
    this.updateView()
  }

  private async getMatchingUniversities(): Promise<void> {
    if (this.input.length >= 3) {
      try {
        const result = await this.fetcher.fetch({
          method: 'GET',
          url: `${serverBaseUrl}universities?search=${this.input}`,
          body: null
        })
        if (Array.isArray(result)) {
          this.universities = result
        }
      } catch (err) {
        this.universities = []
      }
    }
    this.updateView()
  }

  private initializeRoleFilters(dtos: RecruitingRoleTypeDto[]): void {
    this.roleFilters = dtos.map((dto) => {
      return {
        id: dto.role,
        isChecked: false,
        label: `${dto.role} (${dto.description})`
      }
    })
  }

  public clearView(): void {
    this.view = null
  }

  public setView(view: ISubscribingView): void {
    this.view = view
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public getWorkSituationFilters(): CheckBoxDto[] {
    return this.workSituationFilters
  }

  public setWorkSituationFilters(label: string): void {
    for (let i = 0; i < this.workSituationFilters.length; i++) {
      const filter = this.workSituationFilters[i]
      if (filter.id === label) {
        this.workSituationFilters[i].isChecked = !this.workSituationFilters[i].isChecked
      }
    }

    this.updateView()
  }

  public getRoleFilters(): CheckBoxDto[] {
    return this.roleFilters
  }

  public setRoleFilters(label: string): void {
    for (let i = 0; i < this.roleFilters.length; i++) {
      const filter = this.roleFilters[i]
      if (filter.id === label) {
        this.roleFilters[i].isChecked = !this.roleFilters[i].isChecked
      }
    }

    this.updateView()
  }

  public getScoreFilters(): CheckBoxDto[] {
    return this.scoreFilters
  }

  public setScoreFilters(label: string): void {
    for (let i = 0; i < this.scoreFilters.length; i++) {
      const filter = this.scoreFilters[i]
      if (filter.id === label) {
        this.scoreFilters[i].isChecked = !this.scoreFilters[i].isChecked
      }
    }

    this.updateView()
  }

  public getSortOrderOptions(): CheckBoxDto[] {
    return this.sortOrderOptions
  }

  public getSelectSortOrderOption(): string {
    return this.sortOrderOptions.find((option) => option.isChecked)?.id || ''
  }

  public setSortOrderFilters(label: string): void {
    for (let i = 0; i < this.sortOrderOptions.length; i++) {
      const filter = this.sortOrderOptions[i]
      if (filter.id === label) {
        this.sortOrderOptions[i].isChecked = !this.sortOrderOptions[i].isChecked
      } else {
        this.sortOrderOptions[i].isChecked = false
      }
    }
    if (!this.sortOrderOptions.find((option) => option.isChecked === true)) {
      this.sortOrderOptions[0].isChecked = true
    }
    this.updateView()
  }
}

export default CandidatePoolFilterPresenter
